import axios from "axios";
const API = "documents";

export default {
  async get() {
    return await axios
      .get(API)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  create(item) {
    return axios
      .post(API, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  delete(id) {
    return axios
      .delete(API + `/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async edit(id, item) {
    return axios
      .patch(API + `/${id}`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  // -----Document transaction

  createTransaction(item) {
    return axios
      .post("document-transaction", item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  deleteTransaction(id) {
    return axios
      .delete(`document-transaction/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async editTransaction(id, item) {
    return axios
      .patch(`document-transaction/${id}`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getTransactionByDocumentId(document_id) {
    return await axios
      .get(`document-transaction/by_documentId/${document_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  // end doc transaction
  async getMasterState() {
    return await axios
      .get(`master-state`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getMasterAction() {
    return await axios
      .get(`master-action`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getRole() {
    return await axios
      .get(`role`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
